/* global $, MatgenGlobal, API_ENV, TENANT_ENV, Buffer, Promise */

import { API, Amplify, Auth, Storage } from 'aws-amplify';
import { v4 as UUID } from 'uuid';
import { contactSupport } from '../../-components/data/common-content.js';
import { loadQuestionnaire } from '../../audience-page/data.js';
import { AmplifyConfig, init } from '../config.js';
import {
  authUI,
  killModal,
  requireAuthModal,
  studySelectContentLoading,
} from '../display.js';

export const translateAnswerTags = async (tagsIn = false) => {
  await loadQuestionnaire(MatgenGlobal.questionnaire_id);
  const tags = [];
  const rawTags = tagsIn || MatgenGlobal.answerTags;
  if (rawTags) {
    for (let i = 0; i < rawTags.length; i++) {
      const q = MatgenGlobal.questions.find(
        (q) => q.id === rawTags[i].question_id
      );

      if (q) {
        const a = q.answers.find((a) => a.id === rawTags[i].answer_id);
        if (a) {
          tags.push({
            type: q.section_type,
            q: q.text,
            a: a.text,
          });
        }
      }
    }
  }
  return tags;
};

export const isJsonAnswer = (component) => {
  switch (component) {
    default:
      throw new Error(`Invalid component type: ${component}`);
    case 'date-range':
    case 'img-select-single':
    case 'list-faq':
    case 'list-multi-input':
    case 'multi-input':
    case 'range':
    case 'text':
    case 'textarea':
    case 'richtext':
    case 'upload-single':
      return true;
    case 'select-multiple':
    case 'select-single':
    case 'card-select-single':
    case 'box-select-single':
    case 'list-select-single':
      return false;
  }
};

export const processStudyForm = async () => {
  if ($('#study-name').val() === '') {
    MatgenGlobal.UI.handleError(
      'folder name missing',
      '<o>You must enter a folder name to create a folder.</p>'
    );
    $('#study-name').addClass('error');
    $('#study-name').prev().addClass('error');
    return false;
  }
  const newId = UUID();
  const study = {
    id: newId,
    name: $('#study-name').val(),
  };
  if ($('#study-id').val()) {
    study.data = JSON.stringify({
      study_id: $('#study-id').val() ? $('#study-id').val() : 'default-folder',
    });
  }
  studySelectContentLoading();
  const user = await MatgenGlobal.Amplify.Auth.currentUserCredentials();
  study.user_name = user.identityId;
  try {
    await MatgenGlobal.Data.API.request(`/user-folders`, 'POST', study);
  } catch (e) {
    console.error(e);
    MatgenGlobal.UI.handleError(
      'Error',
      `There was an error saving your study info. Please try again later or ${contactSupport}.`
    );
  }
  return newId;
};

export const getQueryParam = (key) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key);
};

const pushQuery = (arr, name, value) => {
  arr.push({
    name,
    value,
  });
};

export const buildQueryString = ({
  tags,
  page,
  template_id,
  grant_number,
  material_id,
  study_id,
  page_id,
  opener,
  theme_color,
} = {}) => {
  if (typeof page === 'undefined') {
    page = MatgenGlobal.getQueryParam('page');
  }
  const vars = [];
  if (tags) {
    const base64Data = new Buffer.from(JSON.stringify(tags));
    pushQuery(vars, 'tags', encodeURIComponent(base64Data.toString('base64')));
  }
  if (page) {
    pushQuery(vars, 'page', page);
  }
  if (template_id) {
    pushQuery(vars, 'template_id', template_id);
  }
  if (material_id) {
    pushQuery(vars, 'material_id', material_id);
  }
  if (study_id) {
    pushQuery(vars, 'study_id', study_id);
  }
  if (page_id) {
    pushQuery(vars, 'page_id', page_id);
  }
  if (opener) {
    pushQuery(vars, 'opener', opener);
  }
  if (theme_color) {
    pushQuery(vars, 'theme_color', theme_color);
  }

  if (grant_number) {
    pushQuery(vars, 'grant_number', grant_number);
  }

  const query = vars.map((v) => `${v.name}=${v.value}`).join('&');
  const url = `${query.length > 0 ? `?${query}` : ''}`;
  return url;
};

export const alertModal = (
  title,
  content,
  classes = '',
  modalId = 'alert-modal',
  buttons = []
) => {
  MatgenGlobal.M4CModal.show({
    id: modalId,
    title,
    header: true,
    footer: false,
    content: `
      <div class="alert-modal-content-wrapper">
        ${content}
      </div>
    `,
    buttons,
    classes,
  });
};

export const emit = ({
  event,
  detail = null,
  target = document,
  bubbles = true,
}) => {
  //console.error('EMIT:', event, MatgenGlobal.userInitialized);
  if (event && target) {
    target.dispatchEvent(
      new CustomEvent(event, {
        composed: true,
        detail,
        bubbles,
      })
    );
  }
};

export const configure = async (overrides = {}) => {
  let api_env = 'dev';
  if (API_ENV === 'production') {
    api_env = 'prod';
  } else {
    api_env = API_ENV;
  }

  const coreTenant = TENANT_ENV ? TENANT_ENV : 'm4c';

  console.error('ENV:', api_env, coreTenant);

  MatgenGlobal.Options = {
    env: api_env,
    tenant_env: coreTenant,
    containerId: 'matgen-scale-container',
    //editorTargetSelector: '#matgen-controller',
    controllerTargetSelector: '#nia-matgen-controller',
    sidebarTargetSelector: '#matgen-sidebar',
    rootPage: overrides.rootPage ? overrides.rootPage : 'generator.html',
    amplify: {
      Auth: {
        mandatorySignIn: false,
      },
    },
  };

  MatgenGlobal.Amplify = {
    Auth,
    Storage,
    API,
  };
  MatgenGlobal.AmplifyConfig = AmplifyConfig;
  Amplify.configure(MatgenGlobal.AmplifyConfig);
  await init();

  //console.log('API_ENV:', API_ENV, 'TENANT_ENV:', TENANT_ENV);
  //console.log('AmplifyConfig', MatgenGlobal.AmplifyConfig);

  MatgenGlobal.NavDirection = 0;
  MatgenGlobal.SuppressSidebarActions = true;
  MatgenGlobal.liveContentSelector = '#main-whole';

  MatgenGlobal.loginModalSelector = '#matgen-signin-modal';

  //MatgenGlobal.Suppress508 = true;
  MatgenGlobal.FixedSidebar = true;
  MatgenGlobal.materialData = {};
  MatgenGlobal.useNamespace = false;
  /*MatgenGlobal.MICROSITE_BUCKET =
      API_ENV === 'production' ? 'fmg-nia-new' : 'fmg-nia-dev-new';*/
  MatgenGlobal.MICROSITE_BUCKET = 'm4c-matgen-microsites-new';
  MatgenGlobal.MICROSITE_DOMAIN = 'outreachpro.org';
  if (MatgenGlobal.generator) {
    MatgenGlobal.RootRoute = () => {
      MatgenGlobal.generator.step1();
    };
  }

  MatgenGlobal.PageLoaderTarget = '#m4c-wrapper';

  MatgenGlobal.Tipsheet = {
    file: '/assets/tip-sheet_v1d.pdf',
    name: 'Social Media Tip Sheet',
  };

  MatgenGlobal.emit = emit;
  MatgenGlobal.UI.alertModal = alertModal;
  MatgenGlobal.QuestionnairePage = 'create/audience.html';
  MatgenGlobal.TemplatePickerPage = 'create/template-picker.html';
  MatgenGlobal.WebsiteDataPage = 'create/website-data.html';
  MatgenGlobal.WebsiteManagementPage = 'create/website-management.html';
  MatgenGlobal.EditorPage = 'create/editor.html';
  MatgenGlobal.MaterialSavedPage = 'create/material-saved.html';
  MatgenGlobal.MyMaterialsPage = 'my-materials.html';
  MatgenGlobal.StudyMaterialsPage = 'study-materials.html';
  MatgenGlobal.Openers = {
    my_materials: 'my-materials.html',
    study_materials: 'study-materials.html',
  };
};

export const authHeaderOpts = async () => {
  try {
    const session = await MatgenGlobal.Amplify.Auth.currentSession();
    const token = session.idToken.jwtToken;
    const opts = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return opts;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const requireAuth = async () => {
  try {
    const user = MatgenGlobal.AuthUser
      ? await MatgenGlobal.AuthUser.getUser()
      : false;
    if (!user) {
      requireAuthModal();
      //$('#study-prompt-modal').modal('show');
      return false;
    }
  } catch (e) {
    $('#study-prompt-modal').modal('show');
    return false;
  }
  return true;
};

export const decodeAnswerTags = (tags) => {
  try {
    tags = decodeURIComponent(tags);
    const buff = new Buffer.from(tags, 'base64');
    tags = JSON.parse(buff.toString());
  } catch (e) {
    console.error(e);
    window.location.href = '/${MatgenGlobal.QuestionnairePage}';
  }
  return tags;
};

export const configureFormTags = async () => {
  if (!MatgenGlobal.FormTagsConfigured) {
    const websiteQuestions = await MatgenGlobal.Data.API.request(
      `/questions?type=material&tenant_id=${MatgenGlobal.tenant_id}`
    );
    const locQ = await MatgenGlobal.Amplify.API.get(
      'public',
      `/questions?handle=locations`
    );

    const pathQ = websiteQuestions.find((q) => q.handle === 'path');
    const pathQuestionId = pathQ.question_id;
    const distQ = websiteQuestions.find((q) => q.handle === 'dist');
    const distributionQuestionId = distQ.question_id;
    const langQ = websiteQuestions.find((q) => q.handle === 'language');
    const langQs = websiteQuestions.filter((q) => q.handle === 'language');
    const languageQuestionId = langQ.question_id;
    const ethQ = websiteQuestions.find((q) => q.handle === 'ethnicity');
    const ethnicityQuestionId = ethQ.question_id;
    const healthQ = websiteQuestions.find((q) => q.handle === 'health');
    const healthStatusQuestionId = healthQ.question_id;
    const locationsQuestionId = locQ[0].id;
    const locRes = await MatgenGlobal.Amplify.API.get(
      'public',
      `/answers?question_id=${locationsQuestionId}`
    );
    const locA = locRes.find((l) => l.text.includes('Address Line 1'));
    const locationsAddressLine1QuestionId = locA.id;

    const studySpecificAnswerId = 'b03c4dfa-e4cb-49a5-8f8f-9a17b61be966';

    const pathTag = {
      question_id: pathQuestionId,
      answer_id: 'b03c4dfa-e4cb-49a5-8f8f-9a17b61be966',
      type: 'bool',
    };
    const clincicalStudiesTag = {
      question_id: pathQuestionId,
      answer_id: '2d425dfd-8467-41af-9c74-a20cea8145cb',
      type: 'bool',
    };
    const brainHealthTag = {
      question_id: pathQuestionId,
      answer_id: '78f2fda5-b8a8-4230-a4c8-05e9dfa1efae',
      type: 'bool',
    };

    MatgenGlobal.languageQuestion = langQs.map((q) => {
      return {
        text: q.answer_text,
        question_id: q.question_id,
        id: q.answer_id,
      };
    });
    MatgenGlobal.pathTag = pathTag;
    MatgenGlobal.clincicalStudiesTag = clincicalStudiesTag;
    MatgenGlobal.brainHealthTag = brainHealthTag;
    MatgenGlobal.pathQuestionId = pathQuestionId;
    MatgenGlobal.studySpecificAnswerId = studySpecificAnswerId;
    MatgenGlobal.distributionQuestionId = distributionQuestionId;
    MatgenGlobal.languageQuestionId = languageQuestionId;
    MatgenGlobal.ethnicityQuestionId = ethnicityQuestionId;
    MatgenGlobal.healthStatusQuestionId = healthStatusQuestionId;
    MatgenGlobal.locationsQuestionId = locationsQuestionId;
    MatgenGlobal.locationsAddressLine1QuestionId =
      locationsAddressLine1QuestionId;

    MatgenGlobal.questionnaireInitialized = true;
    MatgenGlobal.emit({
      event: 'matgen-event-questionnaire-initialized',
    });
    MatgenGlobal.FormTagsConfigured = true;
  }
};

export const loadAnswerTags = async () => {
  //console.error('LOAD ANSWER TAGS');
  if (!MatgenGlobal.AnswerTagsLoaded) {
    let tags = MatgenGlobal.getQueryParam('tags');
    const material_id = MatgenGlobal.getQueryParam('material_id');
    if (tags) {
      tags = decodeAnswerTags(tags);
      if (tags && tags !== 'null') {
        const swimlaneTag = tags.find(
          (t) => t.question_id === MatgenGlobal.pathQuestionId
        );
        MatgenGlobal.swimlaneTag = swimlaneTag;
        if (location.href.includes('template-picker') && tags.length < 4) {
          window.location.href = `/${
            MatgenGlobal.QuestionnairePage
          }${MatgenGlobal.buildQueryString({
            tags,
            page: false,
          })}`;
        }
        MatgenGlobal.answerTags = tags;
      }
    } else if (material_id) {
      const material = await MatgenGlobal.Data.getMaterial(material_id);
      const template_tags = await MatgenGlobal.Data.getTemplateTags(
        material.template_id
      );
      if (!material.tags) {
        const tags = await translateAnswerTags(template_tags);
        let pathTag = tags.filter((t) => t.type === 'goal');
        if (pathTag && Array.isArray(pathTag)) {
          pathTag = pathTag[0];
        }
        const untranslatedPathTag = template_tags.find(
          (t) => t.question_id === 'ba6ad823-e74d-42da-a378-af396d5faca8'
        );
        if (pathTag.a !== 'Materials Describing a Specific Study') {
          MatgenGlobal.notStudy = true;
        }
        MatgenGlobal.pathTag = untranslatedPathTag;
        $('#create-another').attr(
          'href',
          `/${MatgenGlobal.QuestionnairePage}${MatgenGlobal.buildQueryString({
            tags: [MatgenGlobal.pathTag],
          })}`
        );
      } else {
        let matTags;
        try {
          matTags = JSON.parse(material.tags);
          const tags = await translateAnswerTags(matTags);
          let pathTag = tags.filter((t) => t.type === 'goal');
          if (pathTag && Array.isArray(pathTag)) {
            pathTag = pathTag[0];
          }
          const untranslatedPathTag = matTags.find(
            (t) => t.question_id === 'ba6ad823-e74d-42da-a378-af396d5faca8'
          );
          if (
            pathTag &&
            pathTag.a !== 'Materials Describing a Specific Study'
          ) {
            MatgenGlobal.notStudy = true;
          }
          MatgenGlobal.pathTag = untranslatedPathTag;
          $('#create-another').attr(
            'href',
            `/${MatgenGlobal.QuestionnairePage}${MatgenGlobal.buildQueryString({
              tags: [MatgenGlobal.pathTag],
            })}`
          );
        } catch (e) {
          console.error('Bad tag JSON', e, material.tags);
        }
        MatgenGlobal.answerTags = matTags;
      }
    } else {
      MatgenGlobal.emit({
        event: 'matgen-event-error-no-tags',
      });
    }
    MatgenGlobal.emit({
      event: 'matgen-event-tags-loaded',
    });
    MatgenGlobal.AnswerTagsLoaded = true;
  }
};

export const createThumbnail = async (
  id,
  material_id,
  type = 'template',
  width = 300
) => {
  //console.error('CREATING THUMB:', id, material_id, type);
  let srcURL;
  if (type !== 'material') {
    srcURL = MatgenGlobal.Data.getTemplateFileURL(
      `${id}`,
      MatgenGlobal.tenant_id,
      '.png'
    );
  } else {
    const credentials =
      await MatgenGlobal.Amplify.Auth.currentUserCredentials();
    srcURL = `https://${MatgenGlobal.AmplifyConfig.Storage.bucket}.s3.amazonaws.com/protected/${credentials.identityId}/${material_id}/${id}.png`;
  }

  return new Promise((resolve) => {
    const img = new Image();
    img.setAttribute('crossorigin', 'anonymous');
    img.onload = async () => {
      const canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d'),
        oc = document.createElement('canvas'),
        octx = oc.getContext('2d');

      document.body.appendChild(canvas);

      canvas.width = width;
      canvas.height = (canvas.width * img.height) / img.width;

      let cur = {
        width: Math.floor(img.width * 0.5),
        height: Math.floor(img.height * 0.5),
      };

      oc.width = cur.width;
      oc.height = cur.height;

      octx.drawImage(img, 0, 0, cur.width, cur.height);

      while (cur.width * 0.5 > width) {
        cur = {
          width: Math.floor(cur.width * 0.5),
          height: Math.floor(cur.height * 0.5),
        };
        octx.drawImage(
          oc,
          0,
          0,
          cur.width * 2,
          cur.height * 2,
          0,
          0,
          cur.width,
          cur.height
        );
      }

      ctx.drawImage(
        oc,
        0,
        0,
        cur.width,
        cur.height,
        0,
        0,
        canvas.width,
        canvas.height
      );

      const durl = canvas.toDataURL('image/jpeg', 0.7);
      if (type === 'material') {
        const base64Data = new Buffer.from(
          durl.replace(/^data:image\/\w+;base64,/, ''),
          'base64'
        );
        await MatgenGlobal.Data.saveMaterialImage(
          base64Data,
          material_id,
          `${id}-thumbnail`
        );
      } else {
        try {
          await MatgenGlobal.Data.saveTemplateFile(
            `${id}-thumbnail.jpg`,
            MatgenGlobal.tenant_id,
            durl
          );
        } catch (e) {
          console.error(e);
        }
      }
      canvas.remove();
      resolve(srcURL);
    };

    img.src = srcURL;
  });
};

const getFileThumbURL = (template) => {
  if (template.preview_type === 'IMAGE') {
    return `https://${MatgenGlobal.AmplifyConfig.Storage.bucket}.s3.amazonaws.com/tenant/${template.tenant_id}/templates/${template.id}${template.preview_image_ext}`;
  } else {
    if (template.type === 'VIDEO') {
      return '/assets/img/icon-video.svg';
    }
    return '/assets/img/file-lines-regular.svg';
  }
  //thumbnail = `https://${MatgenGlobal.AmplifyConfig.Storage.bucket}.s3.amazonaws.com/tenant/${template.tenant_id}/templates/${template.id}-thumbnail.png`;
};

export const getTemplateThumbUrl = async (
  template,
  pages = [],
  pageNumber = 0
) => {
  let thumbnail = false;
  if (template.type === 'WEBSITE') {
    thumbnail = '/assets/img/microsite-1-thumb.png';
  } else if (template.type === 'FILE' || template.type === 'VIDEO') {
    thumbnail = getFileThumbURL(template);
  } else {
    if (pages.length !== 0) {
      thumbnail = MatgenGlobal.Data.getTemplateFileURL(
        pages[pageNumber].id,
        template.tenant_id,
        '.png'
      );
    } else {
      thumbnail = MatgenGlobal.Data.getTemplateFileURL(
        `${template.page_1_id ? template.page_1_id : template.id}`,
        template.tenant_id,
        '.png'
      );
      /*
      if (MatgenGlobal.createThumbs) {
        thumbnail = await createThumbnail(
          template.page_1_id ? template.page_1_id : template.id
        );
      }
      */
    }
  }
  return `${thumbnail}?cache_buster=${new Date().getTime()}`;
};

export const getMaterialThumbUrl = ({
  material,
  template,
  pages = [],
  pageNumber = 0,
  credentials,
  pageId = false,
} = {}) => {
  let thumbnail = false;
  if (template.type === 'WEBSITE') {
    thumbnail = '/assets/img/microsite-1-thumb.png';
  } else if (template.type === 'FILE' || template.type === 'VIDEO') {
    thumbnail = getFileThumbURL(template);
  } else {
    if (pages.length !== 0) {
      thumbnail = `https://${
        MatgenGlobal.AmplifyConfig.Storage.bucket
      }.s3.amazonaws.com/protected/${credentials.identityId}/${material.id}/${
        pageId ? pageId : pages[pageNumber].id
      }-thumbnail.png`;
    } else {
      thumbnail = `https://${
        MatgenGlobal.AmplifyConfig.Storage.bucket
      }.s3.amazonaws.com/protected/${credentials.identityId}/${material.id}/${
        pageId ? pageId : pages[0].id
      }-thumbnail.png`;
    }

    /*try {
      await $.ajax({
        url: thumbnail,
        type: 'HEAD',
        error: async () => {
          $('#loader-message').text('Creating material thumbnail...');
          thumbnail = createThumbnail(pages[0].id, material.id, 'material');
        },
      });
    } catch (e) {
      console.error(e);
    }*/
  }
  return thumbnail;
};

export const authCallback = async () => {
  console.error('AUTH CALLBACK!!!!!!');
  const tenant = MatgenGlobal.AuthUser.user.attributes['custom:tenant'];
  //console.log(MatgenGlobal.AuthUser.user);
  if (!tenant || tenant !== 'nia') {
    MatgenGlobal.UI.handleError(
      'Authentication Error',
      `<p>There was an error authenticating your user. Please try again or ${contactSupport}.<p>`
    );
    await MatgenGlobal.Amplify.Auth.signOut({ global: false });
    authUI();
    return false;
  }

  if (MatgenGlobal.AuthUser.getUserRole() === 'admin') {
    if (window.location.pathname !== 'admin/templates.html') {
      window.location.href = 'admin/templates.html';
    }
  }
  //$('#matgen-signin-modal').modal('hide');
  killModal('#matgen-signin-modal');
  if (window.location.pathname === '/my-materials.html') {
    window.location.reload();
  }
  authUI();
};
